<template>
  <div>
    <b-row class="justify-content-center">
      <b-col
        sm="12"
        lg="6"
        class="d-flex justify-content-center"
        style="margin-bottom:-15px; padding:0px;"
      >
        <!-- apex chart -->
        <vue-apex-charts
          type="radialBar"
          height="300"
          :options="radialChartOptionsComputed(data.radial,'decimal')"
          :series="data.radial.series"
        />
        <div
          class="change-indicator-large"
          :style="{color:data.radial.colorB}"
        >
          <feather-icon
            :icon="data.radial.change.icon"
            size="44"
          />
        </div>
      </b-col>
      <b-col
        sm="12"
        lg="6"
      >
        <div style="padding:20px;">
          <h4>{{ data.text.title }}</h4>
          <div v-html-safe="data.text.body" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div style="padding:20px;">
          <H4>"{{ data.name }} Score is {{ data.radial.series[0]/10 }}"</H4>
          <p v-if="data.radial.series[0] < 30">
            Your {{ data.name }} score is <b>Very low</b>; There is much room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 50">
            Your {{ data.name }} score is <b>Rather low</b>; There is much room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 70">
            Your {{ data.name }} score is <b>Average</b>; There is some further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else-if="data.radial.series[0] < 90">
            Your {{ data.name }} score is <b>Good</b>; There is still further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-else>
            Your {{ data.name }} score is <b>Excellent</b>; There always further room for improvement. {{ data.text.p1 }} <b>{{ data.text.percentage }}%</b> {{ data.text.p2 }}
          </p>
          <p v-if="!data.isLowest">
            However, your <b>{{ data.lowestScores }}</b> scores are lowest, and may be the best place to focus first. Head over to the <b-link href="/actions">
              Actions Page
            </b-link> to see how to increase <b>{{ data.lowestScores }}</b>
          </p>
          <p v-if="data.isLowest">Head over to the <b-link href="/actions">
            Actions Page
          </b-link> to see how to increase {{ data.name }}.
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          responsive="sm"
          :items="data.items"
        />
      </b-col>
    </b-row>
    <div
      v-if="Object.keys(data.heatmap).length"
    >
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <h4>{{ data.name }} in detail</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:0px 20px 0px 20px;">
          <b-dropdown
            :text="data.activeBreakdownName"
            class="m-md-2"
          >
            <b-dropdown-item
              v-for="(item, key) in data.breakdownList"
              :key="key"
              @click="data.activeBreakdownName = item.value"
            >
              {{ item.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <div id="chart">
            <vue-apex-charts
              type="heatmap"
              :options="getGridChartOptions"
              :series="getGridChartData"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="Object.keys(data.onaGraphs).length"
    >
      <b-row>
        <b-col style="padding:20px 20px 0px 20px;">
          <h4>{{ data.name }} Organisational Network Analysis</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="padding:0px 20px 0px 20px;">
          <b-dropdown
            :text="data.activeOnaGroup"
            class="m-md-2"
          >
            <b-dropdown-item
              v-for="(item, key) in data.onaGroups"
              :key="key"
              @click="data.activeOnaGroup = item.value"
            >
              {{ item.text }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          lg="6"
          style="padding:0px 20px 0px 20px;"
        >
          <d3-network
            :net-nodes="getNodes"
            :net-links="getLinks"
            :options="options"
          />
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div style="padding:20px;">
            <h4
              v-b-tooltip.hover.focus
              :title="data.text.hoverGraphTitle"
            >{{ data.networkDensity.text.measure }} Network <feather-icon
              icon="InfoIcon"
              size="15"
            /></h4>
            <div /></div></b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          lg="6"
          class="d-flex justify-content-center"
          style="margin-bottom:-15px; padding:0px;"
        >
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="300"
            :options="radialChartOptionsComputedONA(data.networkDensity,'networkDensity', 100, [25,51], 'percentage')"
            :series="getOnaSeriseData('networkDensity', 100)"
          />
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div style="padding:20px;">
            <h4
              v-b-tooltip.hover.focus
              :title="data.networkDensity.text.hover"
            >{{ data.networkDensity.text.measure }} {{ data.networkDensity.text.title }} <feather-icon
              icon="InfoIcon"
              size="15"
            /></h4>
            <div>
              <p>

                <span v-if="getOnaData('networkDensity', 100) < 25">
                  This score is <b>Low</b>; There is much room for improvement.
                </span>
                <span v-else-if="getOnaData('networkDensity', 100) < 51">
                  This score is <b>Average</b>; There is room for improvement.
                </span>
                <span v-else>
                  This score is <b>Good</b>; There may be room for improvement.
                </span>
                Scores above <b>50%</b> indicate a dense network, which is good while scores below <b>25%</b> indicate a sparse network.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          lg="6"
          class="d-flex justify-content-center"
          style="margin-bottom:-15px; padding:0px;"
        >
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="300"
            :options="radialChartOptionsComputedONA(data.deCentralisation,'decentralisationCoefficient', 100, [38,63], 'percentage')"
            :series="getOnaSeriseData('decentralisationCoefficient', 100)"
          />
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div style="padding:20px;">
            <h4
              v-b-tooltip.hover.focus
              :title="data.deCentralisation.text.hover"
            >{{ data.deCentralisation.text.measure }} {{ data.deCentralisation.text.title }} <feather-icon
              icon="InfoIcon"
              size="15"
            /></h4>
            <div>
              <p>
                <span v-if="getOnaData('decentralisationCoefficient', 100) < 38">
                  This score is <b>Low</b>; There is much room for improvement.
                </span>
                <span v-else-if="getOnaData('decentralisationCoefficient', 100) < 63">
                  This score is <b>Average</b>; There is room for improvement.
                </span>
                <span v-else>
                  This score is <b>Good</b>; There may be room for improvement.
                </span>
                Scores above <b>62%</b> indicate a decentralised network, which is good, while scores below <b>38%</b> indicate a centralised network.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          lg="6"
          class="d-flex justify-content-center"
          style="margin-bottom:-15px; padding:0px;"
        >
          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="300"
            :options="radialChartOptionsComputedONA(data.networkIntegrity,'networkIntegrity', 100, [80,91],'percentage')"
            :series="getOnaSeriseData('networkIntegrity', 100)"
          />
        </b-col>
        <b-col
          sm="12"
          lg="6"
        >
          <div style="padding:20px;">
            <h4
              v-b-tooltip.hover.focus
              :title="data.networkIntegrity.text.hover"
            >{{ data.networkIntegrity.text.measure }} {{ data.networkIntegrity.text.title }} <feather-icon
              icon="InfoIcon"
              size="15"
            /></h4>
            <div>
              <p>

                <span v-if="getOnaData('networkVacuumCount', 1) > 0">
                  We have detected {{ getOnaData('networkVacuumCount', 1) }} dead zones within your {{ data.networkIntegrity.text.measure }} network.
                </span>
                <span v-if="getOnaData('networkIntegrity', 100) < 80">
                  This score is <b>Low</b>; There is much room for improvement.
                </span>
                <span v-else-if="getOnaData('networkIntegrity', 100) < 91">
                  This score is <b>Average</b>; There is room for improvement.
                </span>
                <span v-else-if="getOnaData('networkIntegrity', 100) < 100">
                  This score is <b>Average</b>; There is room for improvement.
                </span>
                <span v-else>
                  This score is <b>Good</b>.
                </span>
                While network integrity above <b>90%</b> is considered good, a score of <b>100%</b> can and should be achieved.
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BTable, BDropdown, BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import D3Network from 'vue-d3-network'
import radialChartOptions from '../analytics/chartOptions'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BTable,
    D3Network,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    options() {
      console.log(this.data)
      return {
        force: 3000,
        size: { w: 500, h: 500 },
        nodeSize: 17,
        nodeLabels: false,
        canvas: false,
        linkWidth: 4,
      }
    },
    getNodes() {
      return (Object.keys(this.data.onaGraphs).length) ? this.data.onaGraphs[this.data.activeOnaGroup].nodes : {}
    },
    getLinks() {
      return (Object.keys(this.data.onaGraphs).length) ? this.data.onaGraphs[this.data.activeOnaGroup].links : {}
    },
    getGridChartData() {
      return (Object.keys(this.data.heatmap).length) ? this.data.heatmap[this.data.activeBreakdownName].rows : {}
    },
    getGridChartOptions() {
      const groupNames = typeof this.data.heatmap[this.data.activeBreakdownName] !== 'undefined' ? this.data.heatmap[this.data.activeBreakdownName].groupNames : []
      return {
        chart: {
          height: 'auto',
          type: 'heatmap',
        },
        plotOptions: {
          heatmap: {
            enableShades: false,
            radius: 8,
            colorScale: {
              ranges: [{
                from: 0,
                to: 0,
                name: 'No Data',
                color: '#eeeeee',
              }, {
                from: 0.1,
                to: 3.7,
                name: 'low',
                color: '#FF6E6E',
              },
              {
                from: 3.8,
                to: 6.2,
                name: 'neutral',
                color: '#FCBF1C',
              },
              {
                from: 6.3,
                to: 10,
                name: 'high',
                color: '#6FCF97',
              },
              ],
              min: 0.1,
              max: 10,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 5,
        },
        xaxis: {
          type: 'group',
          categories: groupNames,
          labels: {
            rotate: -45,
            rotateAlways: true,

          },
          position: 'bottom',

        },
        yaxis: {
          labels: {
            rotate: -45,
            rotateAlways: true,
          },
        },
      }
    },
  },
  methods: {
    getOnaData(stat, multiplier) {
      return multiplier * ((Object.keys(this.data.onaStats).length) ? this.data.onaStats[this.data.activeOnaGroup][stat] : 0)
    },
    getOnaSeriseData(stat, multiplier) {
      return [this.getOnaData(stat, multiplier)]
    },
    radialChartOptionsComputedONA(chart, stat, multiplier, thresholds, type) {
      const options = JSON.parse(JSON.stringify(radialChartOptions))
      const score = this.getOnaData(stat, multiplier)
      const colors = { colorA: '#3EBA72', colorB: '#6FCF9F', colorBG: '#CBEEDC' } // score is good
      if (score < thresholds[0]) { // score is low
        colors.colorA = '#FF2222'
        colors.colorB = '#FF6E6E'
        colors.colorBG = '#FFCCCC'
      } else if (score < thresholds[1]) { // score is average
        colors.colorA = '#EDAF04'
        colors.colorB = '#FCBF1C'
        colors.colorBG = '#FEE9AE'
      }

      options.colors = [colors.colorB]
      options.fill.gradient.gradientToColors = [colors.colorA]
      options.plotOptions.radialBar.track.background = [colors.colorBG]

      if (type === 'decimal') {
        /* eslint func-names: ["error", "never"] */
        options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
          return Math.round(val * 100) / 1000
        }
        options.plotOptions.radialBar.dataLabels.value.fontSize = '5rem'
        options.plotOptions.radialBar.dataLabels.value.offsetY = 10
      }

      if (type === 'percentage') {
        /* eslint func-names: ["error", "never"] */
        options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
          return `${Math.round(val)}%`
        }
        options.plotOptions.radialBar.dataLabels.value.fontSize = '3rem'
        options.plotOptions.radialBar.dataLabels.value.offsetY = 0
      }
      return options
    },
    radialChartOptionsComputed(chart, type) {
      const options = JSON.parse(JSON.stringify(radialChartOptions))
      options.colors = [chart.colorB]
      options.fill.gradient.gradientToColors = [chart.colorA]
      options.plotOptions.radialBar.track.background = [chart.colorBG]

      if (type === 'decimal') {
        /* eslint func-names: ["error", "never"] */
        options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
          return Math.round(val * 100) / 1000
        }
        options.plotOptions.radialBar.dataLabels.value.fontSize = '5rem'
        options.plotOptions.radialBar.dataLabels.value.offsetY = 10
      }

      if (type === 'percentage') {
        /* eslint func-names: ["error", "never"] */
        options.plotOptions.radialBar.dataLabels.value.formatter = function (val) {
          return `${Math.round(val)}%`
        }
        options.plotOptions.radialBar.dataLabels.value.fontSize = '3rem'
        options.plotOptions.radialBar.dataLabels.value.offsetY = 0
      }
      return options
    },
  },
}
</script>
<style src="vue-d3-network/dist/vue-d3-network.css"></style>
<style>
  .node {
    stroke: rgba(0, 0, 0,.6);
    stroke-width: 3px;
    fill: #fff;
  }
  .node:hover {
      stroke: rgba(0, 0, 0,.6);
      stroke-width: 5px;
  }
  .node.pinned {
    stroke: rgba(0, 0, 0,.6);
  }

  .link:hover {
      stroke: rgba(252, 191, 28,.15);
      stroke-width: 5px;
  }
  .link {
      stroke: rgba(252, 191, 28,.15);
  }
</style>
